import React, { useRef } from 'react'
import { useForm } from 'react-hook-form'
import Button from '../components/Button'
import { Send } from '../components/Icons'
import SEO from '../components/seo'
import METADATA from '../metadata/metadata'
import './contact.scss'

const ContactPage = () => {
  const { handleSubmit, register, errors } = useForm()

  const formRef = useRef(null)

  const onSubmit = values => {
    if (values.phone && values.body) {
      const mailtoLink = document.createElement('a')
      mailtoLink.href = `mailto:alan.g.hicks@icloud.com?subject=Building enquiry&body=My phone number is: ${
        values.phone
      }%0D%0A%0D%0A${values.body.replace(/\n/gi, '%0D%0A')}`
      formRef.current.appendChild(mailtoLink)
      mailtoLink.click()
    }
  }

  return (
    <>
      <SEO
        title={METADATA.contact.title}
        description={METADATA.contact.description}
      />
      <section className="Contact">
        <h1 className="Contact_heading">Get in touch</h1>
        <div className="Contact_sectionWrap">
          <div className="Contact_section">
            <ul className="Contact_details">
              <li>
                <span className="Contact_details_label">Phone</span>
                <a href="tel:07710537685" className="Contact_details_content">
                  07710 537 685
                </a>
              </li>
              <li>
                <span className="Contact_details_label">Email</span>
                <a
                  href="mailto:alan.g.hicks@icloud.com"
                  className="Contact_details_content"
                >
                  alan.g.hicks@icloud.com
                </a>
              </li>
              <li>
                <span className="Contact_details_label">Fax</span>
                <span className="Contact_details_content">01604 786464</span>
              </li>
              <li>
                <span className="Contact_details_label">Address</span>
                <span className="Contact_details_content">
                  19 Bentley Close
                  <br />
                  Rectory Farm
                  <br />
                  Northampton
                  <br />
                  Northamptonshire
                  <br />
                  NN3 5JS
                </span>
              </li>
            </ul>
          </div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            ref={formRef}
            className="Contact_section"
          >
            <h3>Send us a message</h3>
            <label htmlFor="phone">Phone number</label>
            <input
              name="phone"
              type="tel"
              id="phone"
              ref={register({
                required: 'Please fill in your phone number',
              })}
            />
            <p className="Contact_error">
              {errors.phone && errors.phone.message}
            </p>

            <label htmlFor="body">Message</label>
            <textarea
              name="body"
              id="body"
              ref={register({
                required: 'Please fill out the message',
              })}
            />
            <p className="Contact_error">
              {errors.body && errors.body.message}
            </p>

            <Button type="submit" className="Contact_send">
              Send <Send />
            </Button>
          </form>
        </div>
      </section>
    </>
  )
}

export default ContactPage
